import React, { forwardRef, memo, Ref } from "react";
import { useSelector } from "react-redux";

import { useEventTracker } from "hooks/useEventTracker";
import { getIsLoggedIn } from "redux/selectors/user";

import StrainPrizeIcon from "components/Icons/strain-prize.svg";
import TrackImpression from "components/TrackImpression";

type Props = {
  onClick: () => void;
  strainId?: number;
};

const StrainQuizCTA = (
  { onClick, strainId }: Props,
  ref: Ref<HTMLButtonElement>,
) => {
  const { publishEvent } = useEventTracker();
  const isUserLoggedIn = useSelector(getIsLoggedIn);

  return (
    <TrackImpression
      trackingFunction={() => {
        publishEvent({
          action: "impression",
          category: "Strain Quiz",
          label: `start quiz - ${isUserLoggedIn ? "account" : "guest"}`,
          strainId,
        });
      }}
    >
      <button
        onClick={() => {
          publishEvent({
            action: "click",
            category: "Strain Quiz",
            label: `start quiz - ${isUserLoggedIn ? "account" : "guest"}`,
          });
          onClick();
        }}
        ref={ref}
        className={
          "flex justify-between items-center rounded shadow-low w-full flex-col p-xl bg-white border-light-grey"
        }
        data-testid="strain-quiz-cta"
        aria-label="start strain quiz"
      >
        <div className={"flex items-center flex-col mb-2"}>
          <StrainPrizeIcon height={50} width={50} />
          {strainId ? (
            <>
              <span className={"ml-lg font-extrabold mt-1"}>
                Is this your best bud?
              </span>
              <p className="text-xs">
                See how this and other strains match your preferences
              </p>
            </>
          ) : (
            <>
              <span className={"ml-lg font-extrabold mt-1"}>
                Need a suggestion?
              </span>
              <p className="text-xs">
                Take the strain quiz to find your new best buds
              </p>
            </>
          )}
        </div>
        <div className={"button py-sm px-lg"}>start quiz</div>
      </button>
    </TrackImpression>
  );
};

export default memo(forwardRef(StrainQuizCTA));
