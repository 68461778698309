import React, { forwardRef, memo, Ref } from "react";
import classNames from "classnames";

import { useEventTracker } from "hooks/useEventTracker";

import TrackImpression from "components/TrackImpression";

type Props = {
  onClick: () => void;
  inline?: boolean;
};

const StrainMatchLoggedOutBanner = (
  { onClick, inline }: Props,
  ref: Ref<HTMLButtonElement>,
) => {
  const { publishEvent } = useEventTracker();
  return (
    <TrackImpression
      trackingFunction={() =>
        publishEvent({
          action: "impression",
          category: "Strain Quiz",
          label: "match tier 0 - guest",
        })
      }
    >
      <button
        className={classNames(
          "bg-white flex shadow-low  flex-col justify-center rounded-full px-sm h-[100px] w-[100px]",
          {
            "absolute top-[180px] lg:top-[225px] lg:left-[20px] z-10": !inline,
          },
        )}
        onClick={() => {
          publishEvent({
            action: "click",
            category: "Strain Quiz",
            label: "match tier 0 - guest",
          });
          onClick();
        }}
        ref={ref}
        data-testid="strain-match-logged-out-banner"
      >
        <div>
          <span
            className={
              "filter font-extrabold leading-none flex items-center text-center text-xl pl-3"
            }
            style={
              {
                "--tw-blur": "blur(3px)",
                height: 25,
                width: 90,
              } as React.CSSProperties
            }
          >
            ?%
          </span>
          <p
            className={
              "flex font-bold leading-none mb-sm text-xs pt-1 pl-1.5 underline decoration-2 text-green"
            }
          >
            unlock score
          </p>
        </div>
      </button>
    </TrackImpression>
  );
};

export default memo(forwardRef(StrainMatchLoggedOutBanner));
