import { stringify } from "qs";

import { createUserProfileApi } from "api/services/user-profile";
import { Preferences, StrainPreferences } from "custom-types/StrainPreferences";
import logError from "utils/logError";

/**
 *  GET strain preference quiz
 */
const getStrainPreferences = async (
  domainCountryCode: string,
  /** preferences is passed in only when retrieving strain_match_query for logged out users */
  isUserLoggedIn?: boolean,
  existingPreferences?: Preferences[],
): Promise<StrainPreferences> => {
  if (!existingPreferences && !isUserLoggedIn) {
    return {
      preferences: [],
      strain_match_filter: "",
    };
  }

  const userProfileApi = createUserProfileApi(domainCountryCode);
  const params = existingPreferences
    ? { strain_preferences: existingPreferences }
    : {};

  try {
    const {
      data: {
        data: preferences = [],
        metadata: { strain_match_filter = "" },
      },
    } = await userProfileApi.get(
      existingPreferences
        ? "/v1/strain-preference-quiz/query-options"
        : "/v1/current-user/strain-preferences",
      {
        params,
        paramsSerializer: (params) => {
          return stringify(params, { encode: false });
        },
      },
    );

    return {
      preferences,
      strain_match_filter,
    };
  } catch (e) {
    logError(e.message, {
      functionName: "getStrainPreferences",
      service: "userprofile",
      statusCode: e.statusCode,
    });
    return {
      preferences: [],
      strain_match_filter: "",
    };
  }
};

export default getStrainPreferences;
