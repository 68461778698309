import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import getStrainPreferences from "api/requests/userProfileService/getStrainPreferences";
import saveStrainQuiz from "api/requests/userProfileService/saveStrainQuiz";
import { SnackBarMessages, useSnackBar } from "context/SnackBarContext";
import {
  LocallyStoredStrainPreferences,
  Preferences,
} from "custom-types/StrainPreferences";
import useBrowserStorage from "hooks/useBrowserStorage";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import {
  USER_STRAIN_PREFERENCES_LOAD,
  USER_STRAIN_PREFERENCES_RESULTS,
  UserStrainPreferencesLoadAction,
  UserStrainPreferencesResultsAction,
} from "redux/reducers/user";
import {
  getIsLoggedIn,
  getIsUserLoading,
  getUserStrainPreferences,
} from "redux/selectors/user";
import logError from "utils/logError";

const useUserStrainPreferences = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const isUserLoading = useSelector(getIsUserLoading);
  const countryCode = useDomainCountryCode();
  const reduxPreferences = useSelector(getUserStrainPreferences);
  const [storedQuiz, setStoredQuiz, clearStoredQuiz] = useBrowserStorage<
    Partial<LocallyStoredStrainPreferences>
  >("local", "strain_quiz", {});
  const { addSnackBarItem } = useSnackBar();
  const [initialized, setInitialized] = useState<boolean>(false);

  const initializeStrainPreferences = async () => {
    /*
    - If prefs are in redux store already, use that.
    - Otherwise if user is logged in, try to get from user-profile-service.
    - Otherwise try to get from local storage.

    - If we were able to load prefs that weren't in redux, update the store.

    - Manage local storage, user-profile-service, and redux when login status changes.
    */
    if (!initialized && !isUserLoading) {
      setInitialized(true);
      if (reduxPreferences?.preferences?.length) {
        return;
      }

      if (isLoggedIn) {
        // try to read from user-profile-service
        dispatch<UserStrainPreferencesLoadAction>({
          type: USER_STRAIN_PREFERENCES_LOAD,
        });

        const prefData = await getStrainPreferences(countryCode, true);
        dispatch<UserStrainPreferencesResultsAction>({
          preferences: prefData.preferences,
          strainMatchQuery: prefData.strain_match_filter,
          type: USER_STRAIN_PREFERENCES_RESULTS,
        });
      } else if (storedQuiz?.preferences?.length) {
        // not logged in, try to read prefs from local storage
        // but get the strain match query string from api
        dispatch<UserStrainPreferencesLoadAction>({
          type: USER_STRAIN_PREFERENCES_LOAD,
        });
        const prefData = await getStrainPreferences(
          countryCode,
          isLoggedIn,
          storedQuiz.preferences,
        );
        dispatch<UserStrainPreferencesResultsAction>({
          preferences: prefData.preferences,
          strainMatchQuery: prefData.strain_match_filter,
          strainSlug: storedQuiz.strain?.slug,
          type: USER_STRAIN_PREFERENCES_RESULTS,
        });
      }
    }
  };

  const setStrainPreferences = async (
    prefs: Preferences[],
    strainSlug?: string,
  ) => {
    if (!prefs?.length) {
      return {};
    } // don't save blank prefs
    /*
        if user is logged in, update user-profile-service (and rm local storage if any).
        if not, update local storage.
        in either case, update redux store.
    */
    if (isLoggedIn) {
      try {
        const { preferences, strain_match_filter } = await saveStrainQuiz(
          prefs,
          countryCode,
        );

        if (preferences?.length) {
          clearStoredQuiz();
          dispatch<UserStrainPreferencesResultsAction>({
            preferences: prefs,
            strainMatchQuery: strain_match_filter,
            type: USER_STRAIN_PREFERENCES_RESULTS,
          });
          addSnackBarItem(SnackBarMessages.saveStrainPreferences);
        } else {
          logError(
            "useUserStrainPreferences: Unexpected empty result when saving strain quiz prefs",
          );
        }
        return { preferences, strainMatchQuery: strain_match_filter };
      } catch (e) {
        logError(
          `useUserStrainPreferences: Error calling saveStrainQuiz(): ${e.message}`,
        );
      }
    } else {
      const { preferences, strain_match_filter } = await getStrainPreferences(
        countryCode,
        false,
        prefs,
      );
      if (preferences?.length) {
        // swallows errors, so fingers crossed:
        setStoredQuiz({
          preferences: prefs,
          ...(strainSlug ? { strain: { slug: strainSlug } } : {}),
        });
        dispatch<UserStrainPreferencesResultsAction>({
          preferences: prefs,
          strainMatchQuery: strain_match_filter,
          strainSlug: strainSlug,
          type: USER_STRAIN_PREFERENCES_RESULTS,
        });
      } else {
        logError(
          "useUserStrainPreferences: Unexpected empty result when saving strain quiz prefs",
        );
      }
      return { preferences, strainMatchQuery: strain_match_filter };
    }

    dispatch<UserStrainPreferencesResultsAction>({
      preferences: prefs,
      strainSlug,
      type: USER_STRAIN_PREFERENCES_RESULTS,
    });
  };

  useEffect(() => {
    if (isLoggedIn && storedQuiz?.preferences?.length) {
      // when user logs in, move prefs from local storage to user-profile-service
      saveStrainQuiz(storedQuiz?.preferences, countryCode)
        .then(() => {
          clearStoredQuiz();
        })
        .catch((e) => {
          logError(e.message, {
            functionName:
              "useUserStrainPreferences.initializeStrainPreferences",
          });
        });
    } else if (!isLoggedIn && !storedQuiz?.preferences?.length) {
      // when user logs out, clear prefs from redux
      dispatch<UserStrainPreferencesResultsAction>({
        preferences: [],
        strainMatchQuery: "",
        type: USER_STRAIN_PREFERENCES_RESULTS,
      });
    }
  }, [isLoggedIn]);

  useEffect(() => {
    initializeStrainPreferences();
  }, [isUserLoading]);

  return {
    setStrainPreferences,
  };
};

export default useUserStrainPreferences;
