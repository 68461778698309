import { type AxiosError } from "axios";

import serviceRequest from "api/serviceRequest";
import publicConfig from "config/public";
import formatAndReturnRejectedError from "utils/formatAndReturnRejectedError";

export const createUserProfileApi = (domainCountryCode: string) => {
  // Configure and set upstream URL
  const userProfileApi = serviceRequest();
  userProfileApi.defaults.withCredentials = true;

  // Initialize the service request with the app and environment
  userProfileApi.defaults.baseURL =
    domainCountryCode === "CA"
      ? publicConfig.services.userProfileCaApi.url
      : publicConfig.services.userProfileApi.url;

  userProfileApi.interceptors.response.use(
    (response) => response,
    (error: AxiosError<{ status: number; message?: string }>) =>
      formatAndReturnRejectedError(
        error,
        error.response?.data?.message,
        error.response?.data?.status,
      ),
  );
  return userProfileApi;
};
