import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import dynamic from "next/dynamic";
import { useSelector } from "react-redux";

import getStrainMatch from "api/requests/consumerApi/getStrainMatch";
import { useStrainPageContext } from "context/StrainPageContext";
import { StrainMatch } from "custom-types/StrainMatch";
import useUserStrainPreferences from "redux/hooks/useUserStrainPreferences";
import {
  getIsLoggedIn,
  getIsUserLoading,
  getUserStrainPreferences,
} from "redux/selectors/user";

import StrainMatchBanner from "./StrainMatchBanner";
import StrainMatchLoggedOutBanner from "./StrainMatchLoggedOutBanner";
import StrainQuizCTA from "./StrainQuizCTA";
import StrainQuizCTAPlaceholder from "./StrainQuizCTAPlaceholder";

type StrainQuizContainerProps = {
  showResults: boolean;
  inline?: boolean;
};

const StrainQuizModal = dynamic(
  () => import("components/StrainQuiz/StrainQuizModal"),
);

const StrainQuizContainer: React.FC<StrainQuizContainerProps> = ({
  showResults,
  inline,
}) => {
  const {
    strain: { slug: strainSlug, id: strainId },
  } = useStrainPageContext();

  const [isLoading, setIsLoading] = useState(true);
  const [quizIsVisible, setQuizIsVisible] = useState(false);
  const [strainMatch, setStrainMatch] = useState<StrainMatch>({
    match: -1,
    mismatches: {},
    summary: "",
  });

  const [cannotViewMatch, setCannotViewMatch] = useState(false);
  const isUserLoading = useSelector(getIsUserLoading);
  const isUserLoggedIn = useSelector(getIsLoggedIn);

  const strainPreferences = useSelector(getUserStrainPreferences);
  useUserStrainPreferences();

  /*
  User can access the strain match info if:
  - they are logged in, or
  - they are logged out but took the strain quiz on the page for This strain
  */
  const canRetrieveMatch =
    strainPreferences &&
    !strainPreferences.loading &&
    strainPreferences.strainMatchQuery &&
    (isUserLoggedIn ||
      (!isUserLoggedIn && strainPreferences.strainSlug === strainSlug));

  useEffect(() => {
    if (canRetrieveMatch) {
      const retrieveMatch = async () => {
        // strainMatchQuery is guaranteed by canRetrieveMatch, the || is just for type linting:
        await getStrainMatchData(
          strainSlug,
          strainPreferences.strainMatchQuery || "",
        );
        setIsLoading(false);
      };
      retrieveMatch();
    } else if (!isUserLoading) {
      // if we have client-side navigated, clear any leftover match from prior page:
      setStrainMatch({
        match: -1,
        mismatches: {},
        summary: "",
      });
      setIsLoading(false);
    }
  }, [canRetrieveMatch, isUserLoading, strainSlug]);

  const getStrainMatchData = useCallback(
    async (strainSlug: string, matchQueryString: string) => {
      setIsLoading(true);

      const {
        match,
        summary,
        mismatches = {},
      } = await getStrainMatch(strainSlug, matchQueryString);

      if (match > -1) {
        setStrainMatch({ match, mismatches, summary });
      } else {
        return false;
      }

      setIsLoading(false);
      return true;
    },
    [],
  );

  useEffect(() => {
    setCannotViewMatch(
      !isUserLoading &&
        !isUserLoggedIn &&
        !!strainPreferences?.preferences?.length,
    );
  }, [isUserLoading, isUserLoggedIn, strainPreferences]);

  const openQuiz = useCallback(() => {
    setQuizIsVisible(true);
  }, [setQuizIsVisible]);

  const closeQuiz = useCallback(() => {
    setQuizIsVisible(false);
  }, [setQuizIsVisible]);

  const modalFocusReturnRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <div className="mb-lg mt-sm">
        {showResults ? (
          <>
            {cannotViewMatch && !isLoading ? (
              <StrainMatchLoggedOutBanner
                inline={inline}
                onClick={openQuiz}
                ref={modalFocusReturnRef}
              />
            ) : strainMatch.match >= 0 && !isLoading ? (
              <StrainMatchBanner
                inline={inline}
                strainMatch={strainMatch.match}
                onClick={openQuiz}
                ref={modalFocusReturnRef}
              />
            ) : null}
          </>
        ) : (
          <>
            {strainMatch.match === -1 && !isLoading ? (
              <StrainQuizCTA
                strainId={strainId}
                onClick={openQuiz}
                ref={modalFocusReturnRef}
              />
            ) : !showResults && isLoading ? (
              <StrainQuizCTAPlaceholder />
            ) : null}
          </>
        )}
      </div>

      {quizIsVisible && (
        <StrainQuizModal
          // deep copy preferences to prevent redux state mutation errors:
          existingPreferences={JSON.parse(
            JSON.stringify(strainPreferences?.preferences),
          )}
          getStrainMatch={getStrainMatchData}
          isGated={cannotViewMatch}
          onQuizClose={closeQuiz}
          returnFocusRef={modalFocusReturnRef}
          strainMatch={strainMatch}
        />
      )}
    </>
  );
};

export default memo(StrainQuizContainer);
