import { createUserProfileApi } from "api/services/user-profile";
import { Preferences, StrainPreferences } from "custom-types/StrainPreferences";
import logError from "utils/logError";

const saveStrainQuiz = async (
  preferences: Preferences[],
  domainCountryCode: string,
): Promise<StrainPreferences> => {
  try {
    const userProfileServiceApi = createUserProfileApi(domainCountryCode);
    const {
      data: {
        metadata: { strain_match_filter = "" },
        data = [],
      },
    } = await userProfileServiceApi.post(
      "/v1/current-user/strain-preferences",
      {
        strain_preferences: preferences,
      },
    );

    return { preferences: data, strain_match_filter };
  } catch (e) {
    logError(e.message, {
      functionName: "saveStrainQuiz",
      service: "userprofile",
      statusCode: e.statusCode,
    });
    return { preferences: [], strain_match_filter: "" };
  }
};

export default saveStrainQuiz;
