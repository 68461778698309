import React, { forwardRef, memo, Ref } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";

import { useEventTracker } from "hooks/useEventTracker";
import { getIsLoggedIn } from "redux/selectors/user";

import TrackImpression from "components/TrackImpression";

import getStrainQuizMatchTier from "./getStrainQuizMatchTier";

type Props = {
  strainMatch: number;
  inline?: boolean;
  onClick: () => void;
};

const StrainMatchBanner = (
  { strainMatch, onClick, inline }: Props,
  ref: Ref<HTMLButtonElement>,
) => {
  const {
    backgroundClasses,
    tier: matchTier,
    text: matchText,
  } = getStrainQuizMatchTier(strainMatch);

  const { publishEvent } = useEventTracker();
  const isUserLoggedIn = useSelector(getIsLoggedIn);

  return (
    <TrackImpression
      trackingFunction={() =>
        publishEvent({
          action: "impression",
          category: "Strain Quiz",
          label: `match tier ${matchTier} - ${
            isUserLoggedIn ? "account" : "guest"
          }`,
        })
      }
    >
      <button
        className={classNames(
          backgroundClasses,
          "flex shadow-low flex-col justify-center rounded-full px-sm h-[100px] w-[100px]",
          {
            "absolute  top-[180px] lg:top-[225px] lg:left-[20px] z-10": !inline,
          },
        )}
        onClick={() => {
          publishEvent({
            action: "click",
            category: "Strain Quiz",
            label: `match tier ${matchTier} - ${
              isUserLoggedIn ? "account" : "guest"
            }`,
          });
          onClick();
        }}
        ref={ref}
        data-testid="strain-match-banner"
      >
        <div>
          <span className="flex justify-center w-[84px] filter font-extrabold leading-none text-center text-xl">
            {strainMatch}%
          </span>
          <p className="flex justify-center w-[84px] font-bold leading-none mb-sm text-xs pt-1 underline">
            {matchText}
          </p>
        </div>
      </button>
    </TrackImpression>
  );
};

export default memo(forwardRef(StrainMatchBanner));
